import request from '@/utils/request'

//获取院系列表
export function getcollegelist(params) {
    return request({
        url: '/portal/college/get-list',
        method: 'GET',
        params
    })
}

//获取类型字典列表 
//参数:  name:类型名称   is_all:是否拉取全部数据，0：否，1：是 
// type: 0：学科分类，1：实验要求，2：实验类型，3：实验者类别，
// 4：实验中心类型，5：实验中心级别，6：实验室类型，7：实验室级别，8：实验用房类型，
// 9：实验用房状态，10：实验中心负责人职位，11：实验中心负责人资料类型，12：专家类别，
// 13：老师职称，14：学历层次，16：开放范围，17：国别，18：设备类别，19：经费科目，
// 20：使用方向，21：设备来源，22：保修期限，23：实习类型，24：基地级别，25：基地性质，26：岗位类型
export function gettypeconfiglist(params) {
    return request({
        url: '/portal/type-config/get-list',
        method: 'GET',
        params
    })
}